@import 'breakpoints.scss';
@import "mixins.scss";

.wrapper {
  position: relative;
  margin: 0 auto;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  width: 100%;
  max-width: $col-xl;

  @include mq(lg) {
    max-width: $col-lg;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
  @include mq(md) {
    max-width: $col-md;
  }
  @include mq(sm) {
    max-width: $col-sm;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  @include mq(s) {
    max-width: initial;
    overflow: hidden;
  }
}

