$full_hd: 1919px;
$laptop_large: 1599px;
$laptop: 1365px;
$desktop: 1279px;
$tablet: 1023px;
$mobile_large: 767px;
$mobile: 419px;
$mobile_small: 374px;

//- global media vars

$xxs:           320px;
$xs:            360px;      // (min-width: 360px) - 16em
$s:             414px;      // (min-width: 414px) - 16em
$sm:            576px;      // (min-width: 576px) - 36em
$md:            768px;      // (min-width: 768px) - 48em
$lg:            992px;      // (min-width: 992px) - 62em
$gl:            1024px;      // (min-width: 1024px) - 64em
$xl:            1200px;      // (min-width: 1200px) - 75em

$breakpoints: (
        xxs: $xxs,
        xs: $xs,
        s: $s,
        sm: $sm,
        md: $md,
        lg: $lg,
        gl: $gl,
        xl: $xl
);

//- to fonts

$h1xl: 48px;
$h1lg: 40px;

//- to container

$col-s:         375px;
$col-sm:        540px;
$col-md:        768px;
$col-lg:        960px;
$col-xl:        1440px;
