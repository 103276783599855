@import "shared/styles/main";

.app {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @include mq(md) {
    gap: 10px;
  }
}

.title {
  @include h1;
  color: $black;
  max-width: 668px;
  > span {
    color: $blue_300
  }
  @include mq(md) {
    @include h3;
  }
}

.topBlockWrapper {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.titleWrapper {
  display: flex;
  border: 1px solid $light_gray;
  padding: 72px 80px;
  border-radius: 28px;
  flex-direction: column;
  gap: 40px;
  @include mq(md) {
    padding: 56px 28px;
    gap: 24px;
    text-align: center;
  }
}

.description {
  @include t1r;
}

.mockupBlock {
  display: flex;
  padding: 48px 80px 64px;
  gap: 32px;
  border-radius: 28px;
  background: $blue_100;
  position: relative;
  &:before {
    content: '';
    background: url("./shared/assets/icon/Dots.svg") no-repeat;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    position: absolute;
    transform: translate(30%, 55%);
  }
  @include mq(md) {
    flex-direction: column;
    padding: 40px 24px 0 24px;
    gap: 0;
    align-items: center;
    height: 784px;
    &:before {
      background-position: center;
      transform: translate(0%, 10%);
    }
  }
}

.mockupBlockText {
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  @include mq(md) {
    align-items: center;
  }
  > div:first-child {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.mockupBlockTitle {
  color: $white;
  display: flex;
  gap: 12px;
  align-items: center;
  @include h3;
  > span {
    padding: 16px 20px;
    background: $blue_200;
    border-radius: 16px;
  }
  @include mq(md) {
    flex-direction: column;
    text-align: center;
    @include h5;
    > span {
      padding: 8px 12px;
    }
  }
}

.mockupBlockButton {
  display: flex;
  background: $white;
  max-width: 208px;
  border-radius: 12px;
  padding: 16px 22px;
  gap: 8px;
  z-index: 1;
  position: relative;
  box-shadow: 0 4px 44px 0 #625FFF;
  @include b1;
  > a {
    color: $black;
  }
}

.mockupBlockDescription {
  @include h4;
  font-size: 27px;
  color: $white;
  display: flex;
  gap: 6px;
  align-items: center;
  @include mq(md) {
    display: block;
    font-size: 22px;
    text-align: center;
  }
}

.mockupBlockDescriptionSpan {
  display: flex;
  gap: 6px;
  align-items: center;
  > span {
    padding: 12px 14px 14px;
    background: $blue_200;
    border-radius: 16px;
  }
  @include mq(md) {
    > span {
      padding: 10px 12px 12px;
      border-radius: 12px;
      white-space: nowrap;
    }
  }
}

.mockupImgWrapper {
  position: absolute;
  right: 5%;
  bottom: -40%;
  z-index: 1;
  overflow: hidden;
  > img {
    position: relative;
    z-index: 2;
  }
  @include mq(md) {
    position: relative;
    right: 0;
    top: 5%;
    > img {
      width: 300px;
    }
  }
}

.mockupVideo {
  position: absolute;
  right: 5px;
  bottom: 0;
  z-index: 1;
  width: 96%;
  height: 100%;
  object-fit: cover;
  border-radius: 70px;
  @include mq(md) {
    border-radius: 50px;
  }
}

.integrationCodeBlock {
  padding: 72px 80px 0;
  gap: 40px;
  display: flex;
  border-radius: 28px;
  border: 1px solid $light_gray;
  flex-direction: column;
  align-items: center;
  @include mq(md) {
    padding: 32px 12px 12px 12px;
    gap: 24px;
    overflow: hidden;
  }
}

.integrationCodeBlockTitle {
  @include h2;
  @include mq(md) {
    @include h4;
    text-align: center;
  }
}

.integrationCodeBlockButtonWrapper {
  display: flex;
  gap: 8px;
  @include mq(md) {
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    max-width: 400px;
    padding: 0 14px;
  }
}

.integrationCodeBlockButton {
  padding: 12px 16px;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid $light_gray;
  color: $black;
  background: white;
  display: flex;
  align-items: center;
  @include t2r;
  > img {
    pointer-events: none;
  }
}

.integrationCodeBlockButtonActive {
  border: 1px solid $blue_300;
  color: $white;
  background: $blue_300;
  > img {
    color: $white;
    stroke: $white;
  }
}

.integrationCodeEdit {
  border-radius: 20px 20px 0 0;
  border: 1px solid $light_gray;
  background: $dark_gray;
  font-family: 'Courier New', Courier, monospace;
  position: relative;
  width: 100%;
  max-width: 1080px;
  height: 678px;
  padding: 20px;
  @include mq(md) {
    border-radius: 16px;
  }
}

.integrationCodeEditWrapper {
  overflow: auto;
  height: 655px;
}

.integrationCodeBlockEditButtonWrapper {
  position: absolute;
  right: 5%;
  top: 5%;
  display: flex;
  gap: 8px;
  z-index: 2;
  @include mq(md) {
    bottom: 2%;
    top: initial;
    left: 5%;
    right: initial;
  }
}

.integrationCodeEditButton {
  padding: 8px 12px;
  gap: 4px;
  border-radius: 12px;
  border: 1px solid rgba(208, 209, 225, 0.25);
  display: flex;
  align-items: center;
  color: $white;
  @include t2r;
  cursor: pointer;
  > a {
    color: $white;
  }
  @include mq(md) {
    background: $white;
    @include t3r;
    color: $black;
    > a {
      color: $black;
    }
    > img {
      filter: invert(100%);
    }
  }
}

.codeBlock {
  color: #ffffff;
  white-space: pre;
}

.questionBlockWrapper {
  padding: 72px 80px;
  gap: 80px;
  display: flex;
  border-radius: 28px;
  border: 1px solid $light_gray;
  @include mq(md) {
    padding: 0;
    border: none;
    flex-direction: column;
    gap: 10px;
  }
}

.questionButtonWrapper, {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  @include mq(md) {
    display: none;
    gap: 8px;
  }
}

.questionButtonWrapperMobile {
  display: none;
  @include mq(md) {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.questionButton {
  display: flex;
  align-items: center;
  gap: 16px;
  border: 1px solid $light_gray;
  border-radius: 16px;
  padding: 32px;
  width: 100%;
  max-width: 620px;
  cursor: pointer;
  @include t2m;
  > img {
    width: 32px;
    height: 32px;
  }
  @include mq(md) {
    padding: 16px 12px 16px 20px;
    flex-direction: row-reverse;
    justify-content: space-between;
    > img {
      transform: rotate(-90deg);
    }
    > div {
      display: block;
    }
  }
}

.сhevronActive {
  filter: invert(100%) saturate(0%) brightness(100%) contrast(100%);
}

.questionButtonAction {
  background: $blue_300;
  color: $white;
}

.questionBlockTitle {
  @include h2;
  margin-bottom: 64px;
  @include mq(md) {
    @include h4;
    margin-bottom: 0;
    text-align: center;
  }
}

.questionBlockAnswerText {
  @include mq(md) {
    display: none;
  }
}

.questionBlockAnswerTextMobile {
  display: none;
  @include mq(md) {
    display: block;
    padding: 0 10px;
  }
}

.questionBlockAnswer {
  max-width: 540px;
  @include mq(md) {
    padding: 24px;
  }
}

.questionAnswerTitle {
  @include h5;
  margin-bottom: 28px;
  @include mq(md) {
    @include t1m;
    margin-bottom: 20px;
  }
}

.questionAnswerDescription {
  @include t2r;
  font-weight: 400;
  @include mq(md) {
    @include t3r;
  }
}

.blueBlock {
  padding: 48px 80px;
  gap: 48px;
  border-radius: 28px;
  display: flex;
  justify-content: center;
  background: $blue_100;
  position: relative;
  overflow: hidden;
  align-items: center;
  &:before {
    content: '';
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    position: absolute;
    transform: translate(33%, 0);
    background: url("./shared/assets/icon/Dots.svg") center no-repeat;
    z-index: 0;
  }
  @include mq(md) {
    flex-direction: column;
    padding: 24px 24px 32px 24px;
    gap: 24px;
    &:before {
      transform: translate(0%, 0);
    }
  }
}

.blueBlockTitle {
  @include h3;
  color: $white;
  @include mq(md) {
    @include h5;
    text-align: center;
  }
}

.activeImage {
  filter: invert(100%);
}

.mockupBlockInputWrapper {
  position: relative;
  max-width: 320px;
  > button {
    position: absolute;
    right: 0;
    width: 60px;
    height: 100%;
    top: 2px;
    border: none;
    padding: 0;
    outline: none;
    background: none;
  }
}

.mockupBlockInput {
  width: 100%;
  padding: 16px 22px;
  border: 1px solid $light_gray;
  border-radius: 12px;
  @include b1;
  color: $black;
  outline: none;
  &::placeholder {
    color: $black;
  }
}

.mockupBlockInputError {
  border: 1px solid red;
}

.mockupBlockSocialWrapper {
  display: flex;
  gap: 20px;
}

.mockupBlockSubscribe {
  display: flex;
  gap: 16px;
  align-items: center;
  @include mq(md) {
    flex-direction: column;
    gap: 28px;
  }
}

.mockupBlockSubscribeOR {
  @include t1r;
  color: white;
  @include mq(md) {
    display: none;
  }
}

.mockupBlockIconSocial {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mockupBlockIconSocialColorBlack {
  background: $black;
}

.mockupBlockIconSocialColorTelegram {
  background: linear-gradient(180deg, #37BBFE 0%, #007DBB 100%);
}

.mockupBlockInputSendEmail {
  @include h5;
  color: white;
}

.mockupBlockInputErrorEmail {
  @include t2m;
  color: red;
  position: absolute;
  bottom: -35px;
}
