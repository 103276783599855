@import "src/shared/styles/breakpoints";
@import "src/shared/styles/colors";
@import "src/shared/styles/container";
@import "src/shared/styles/mixins";

@mixin h1 {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 62px;
  font-weight: 600;
  line-height: 80px;
  letter-spacing: 0;
  color: $black;
}

@mixin h2 {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 64px;
  letter-spacing: 0;
  color: $black;
}

@mixin h3 {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0;
}

@mixin h4 {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0;
}

@mixin h5 {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0;
}

@mixin t1m {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.5px;
}

@mixin t1r {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.5px;
}

@mixin t2m {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
}

@mixin t2r {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
}

@mixin t3m {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
}

@mixin t3r {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
}

@mixin b1 {
  font-family: "IBM Plex Mono", monospace;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0;
}
