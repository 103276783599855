@import "src/shared/styles/main";

.marquee {
  position: relative;
  width: 100%;
  height: 112px;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 32px 0;
  overflow: hidden;
  margin: 20px 0;
  @include mq(md) {
    height: auto;
    margin: 10px 0;
    padding: 24px 0;
  }
}

.marqueeContent {
  display: inline-block;
  animation: mar 50s linear infinite;
  height: 48px;
  > img {
    height: 100%;
    padding-right: 56px;
  }
  @include mq(md) {
    height: 40px;
    > img {
      padding-right: 32px;
    }
  }
}

@keyframes mar {
  0% { transform: translate(0, 0); }
  100% { transform: translate(-100%, 0); }
}
