$black: #000;
$white: #fff;

$dark_gray: #0A1019;
$light_gray: #D2D9E5;

$blue_100: #1E1B9A;
$blue_200: #2A26C6;
$blue_300: #332FED;
$blue_400: #5858FF;
