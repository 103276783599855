@import 'src/shared/styles/breakpoints';
@import "src/shared/styles/colors";

@mixin scrollbar {
  & {
    overflow: auto;
    scrollbar-width: auto;
    cursor: pointer;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey_200;
    border-radius: 8px;
    border-left: 2px solid transparent;
    border-right: 2px solid transparent;
    background-clip: padding-box;

    &:hover {
      background-color: $purple_300;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
    }
  }
}

@mixin resetButton {
  padding: 0;
  border: none;
  background: none;
  outline: none;
}

@mixin buttonAsLink {
  @include resetButton;

  color: $purple_600;
  text-align: left;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: $orange_600;
  }
}

@mixin a11yHide {
  position: fixed;
  width: 1px;
  height: 1px;
  opacity: 0;
}

@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }
}

@mixin sticky {
  position: sticky;
  top: 135px;
}

@mixin textInteractive {
  &:focus-visible {
    color: $orange_500;
  }

  &:active {
    color: $orange_100;
  }

  @media (min-width: $desktop) {
    &:hover {
      color: $orange_500;
    }
  }
}

@mixin activeLabel {
  top: 0;
  left: 8px !important;
  font-size: 14px;
}