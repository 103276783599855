@import "src/shared/styles/main";

.footer {
  padding: 56px 80px 64px;
  border-radius: 28px 28px 0 0;
  background: $dark_gray;
  > div {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  margin-top: 20px;
  > div > nav > a {
    color: $white;
  }
  @include mq(md) {
    margin-top: 10px;
    padding: 40px 24px;
    border-radius: 20px 20px 0 0;
    > div {
      overflow: initial;
    }
    > div > div {
      flex-direction: column;
      align-items: center;
      gap: 40px;
      height: auto;
      &:after {
        content: none;
      }
      > img:nth-child(2){
        display: none;
      }
    }
    > div > div > nav {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }
}

.footerBottom {
  display: flex;
  @include t3r;
  color: $white;
  justify-content: space-between;
}

.footerBottomLinks {
  display: flex;
  gap: 40px;
  > a {
    color: $white;
  }
}


.footerBlockInputWrapper {
  position: relative;
  max-width: 280px;
  > button {
    position: absolute;
    right: 0;
    width: 60px;
    height: 100%;
    top: 2px;
    border: none;
    padding: 0;
    outline: none;
    background: none;
  }
  @include mq(md) {
    width: 100%;
  }
}

.footerBlockInput {
  width: 100%;
  padding: 12px 16px;
  border-radius: 12px;
  @include t3m;
  background: #1F2733;
  color: $white;
  outline: none;
  border: none;
  height: 48px;
  &::placeholder {
    color: $white;
  }
}

.footerBlockInputError {
  border: 1px solid red;
}

.footerBlockSocialWrapper {
  display: flex;
  gap: 12px;
}

.footerBlockSubscribe {
  display: flex;
  gap: 24px;
  align-items: center;
  @include mq(md) {
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }
}

.footerBlockSubscribeDesktop {
  display: flex;
  @include mq(md) {
    display: none;
  }
}

.footerBlockSubscribeMobile {
  display: none;
  @include mq(md) {
    display: flex;
  }
}

.footerBlockSubscribeOR {
  @include t1r;
  color: white;
}

.footerBlockIconSocial {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  border: 1px solid $light_gray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  > img {
    width: 20px;
    height: 20px;
  }
}

.footerImgRevertLogo {
  filter: invert(100%) saturate(0%) brightness(100%) contrast(100%);
}

.footerLogo {
  width: 40px;
  height: 40px;
}

.footerLogoWrapper {
  display: flex;
  gap: 40px;
  align-items: center;
  @include mq(md) {
    flex-direction: column-reverse;
  }
}

.footerText {
  @include t1m;
  color: $white;
  @include mq(md) {
    margin-bottom: 20px;
  }
}

.footerTextWrapper {
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.footerBlockInputSendEmail {
  @include h5;
  color: $white;
}

.footerBlockInputErrorEmail {
  @include t2m;
  color: red;
  position: absolute;
  bottom: -35px;
}
