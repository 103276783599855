@import "src/shared/styles/main";

.header {
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: flex-end;
  @include mq(md) {
    position: relative;
    height: 52px;
    &:after {
      content: "";
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(30, 27, 154, 0.6);
      opacity: 0;
      transition: opacity 0.3s ease;
      z-index: 4;
    }
    &.menuOpen:after {
      opacity: 1;
      display: block;
    }
  }
}

.headerFooter {
  height: inherit;
}

.headerLogo {
  width: 40px;
  height: 40px;
}

.headerNav {
  display: flex;
  gap: 40px;
  margin-bottom: 10px;
  @include mq(md) {
    display: none;
  }
}

.headerNavFooter {
  @include mq(md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
}

.menuOpenNav {
  display: flex;
  position: absolute;
  padding: 24px;
  gap: 32px;
  border-radius: 20px;
  border: 1px solid #D0D1E1;
  z-index: 6;
  flex-direction: column;
  background: $white;
  top: 62px;
  width: 100%;
}

.headerNavLink {
  display: flex;
  gap: 4px;
  align-items: center;
  color: #000000;
  @include t3m;
  > img {
    height: 20px;
    width: 20px;
  }
}

.headerNavLinkRevert {
  color: $white;
}

.headerImgRevert {
  filter: invert(100%);
}

.headerImgRevertLogo {
  filter: invert(100%) saturate(0%) brightness(100%) contrast(100%);
}

.headerBurger {
  display: none;
  @include mq(md) {
    display: block;
    position: relative;
    z-index: 5;
    order: 2;
  }
}

.headerBurgerActive {
  filter: invert(100%);
}

.headerButton {
  display: flex;
  background: $blue_200;
  max-width: 166px;
  border-radius: 12px;
  padding: 12px 16px;
  gap: 8px;
  z-index: 1;
  position: relative;
  @include b1;
  > a {
    color: $white !important;
    font-size: 16px;
  }
  @include mq(md) {
    order: 1;
    padding: 8px 12px;
    > a {
      font-size: 15px;
      line-height: 24px;
    }
    > img {
      display: none;
    }
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url("../../shared/assets/icon/dot-button.svg") center no-repeat;
    left: 0;
    top: 0;
    z-index: -1;
  }
}

.headerFooterText {
  @include t1m;
  color: $white;
  letter-spacing: -0.5px;
}

.headerBurgerWrapper {
  display: flex;
  gap: 16px;
}
